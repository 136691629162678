/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

$(function() {
    // update navbar by context
    let loc = window.location.href;

    if(/professionnel/.test(loc) ||
        /dashboard/.test(loc)
    ) {
        $('nav').removeClass('navbar-transparent');
    }

    if (/contact/.test(loc)) {
        $('nav').removeClass('navbar-transparent');
        $('.sections-page .navbar .container .navbar-brand img')[0].src = '../../build/images/jurilaw_black.png';
    }

    if (
        /mentions-legales/.test(loc) ||
        /conditions-generales-utilisation/.test(loc) ||
        /rgpd/.test(loc) ||
        /information-cookie/.test(loc) ||
        /professionnel/.test(loc)
    ) {
        $('nav').removeClass('navbar-transparent');
        $('.sections-page .navbar .container .navbar-brand img')[0].src = '../build/images/jurilaw_black.png';
    }

    let contentSections = $('.cd-section'),
        navigationItems = $('#cd-vertical-nav a');
    let headerNavigationItems = $('.navbar .__anchor');

    updateNavigation();
    $(window).on('scroll', function() {
        updateNavigation();
    });

    //smooth scroll to the section
    navigationItems.on('click', function(event) {
        event.preventDefault();
        smoothScroll($(this.hash));
    });

    //smooth scroll to the section
    headerNavigationItems.on('click', function(event) {
        event.preventDefault();
        smoothScroll($(this.hash));
    });

    //smooth scroll to second section
    $('.cd-scroll-down').on('click', function(event) {
        event.preventDefault();
        smoothScroll($(this.hash));
    });

    //open-close navigation on touch devices
    $('.touch .cd-nav-trigger').on('click', function() {
        $('.touch #cd-vertical-nav').toggleClass('open');

    });
    //close navigation on touch devices when selectin an elemnt from the list
    $('.touch #cd-vertical-nav a').on('click', function() {
        $('.touch #cd-vertical-nav').removeClass('open');
    });

    function updateNavigation() {
        contentSections.each(function() {
            $this = $(this);
            let activeSection = $('#cd-vertical-nav a[href="#' + $this.attr('id') + '"]').data('number') - 1;
            if (($this.offset().top - $(window).height() / 2 < $(window).scrollTop()) && ($this.offset().top + $this.height() - $(window).height() / 2 > $(window).scrollTop())) {
                navigationItems.eq(activeSection).addClass('is-selected');
            } else {
                navigationItems.eq(activeSection).removeClass('is-selected');
            }
        });
    }

    function smoothScroll(target) {
        $('body,html').animate({
                'scrollTop': target.offset().top - 0
            },
            600
        );
    }

    setTimeout(function() {
        $('.alert').fadeOut('fast');
    }, 4000);


});